import AutorenewRoundedIcon from "@mui/icons-material/AutorenewRounded";
import SensorsRoundedIcon from "@mui/icons-material/SensorsRounded";
import {
  Divider,
  IconButton,
  InputAdornment,
  MenuItem,
  TextField,
  Typography,
} from "@mui/material";
import CopyButton from "components/copy-button/copy-button.component";
import SelectField from "components/formFields/select-field.component";
import ViewingModeController from "components/viewModeController/viewing-mode-controller";
import { useDeviceDetailsHook } from "features/device/device-details/hooks/device-details-hook";
import useDeviceValidation from "features/device/device-details/hooks/device-validation-hook";
import styles from "features/device/device-details/views/device-details-settings/BLEBeacon-settings.module.scss";
import { DeviceFunction } from "features/device/models/device-function";
import { useTranslation } from "react-i18next";
const BLEBeaconSettings = (): JSX.Element => {
  const { t } = useTranslation("deviceDetails");

  const { currentDevice, currentViewingMode, form, generateProximityId } =
    useDeviceDetailsHook();
  const { validateBeacon } = useDeviceValidation();

  const registerOptions = {
    function: {
      required: t("info.requiredHelperText"),
    },
    deviceProximityId: {
      required: t("info.requiredHelperText"),
      validate: validateBeacon,
    },
  };

  const options = [
    <MenuItem key={"internal"} value={DeviceFunction.InternalLocation}>
      {t("BLEBeacon.InternalLocation")}
    </MenuItem>,
    <MenuItem key={"external"} value={DeviceFunction.ExternalLocation}>
      {t("BLEBeacon.ExternalLocation")}
    </MenuItem>,
  ];

  const hasImportDate =
    currentDevice?.importDate !== null &&
    currentDevice?.importDate !== undefined;

  return (
    <>
      <Divider />
      <div className={styles.deviceSettingsContainer}>
        <Typography variant="h5">{t("info.deviceSettingsLabel")}</Typography>
        <div className={styles.beaconSettingsContainer}>
          <div className={styles.label}>
            <SensorsRoundedIcon width={24} height={24} />
            <Typography variant="h5">{t("BLEBeacon.label")}</Typography>
          </div>
          <ViewingModeController
            viewingMode={currentViewingMode}
            name="function"
            control={form.control}
            rules={registerOptions.function}
            render={({ field }) => (
              <SelectField
                {...field}
                label={t("BLEBeacon.functionLabel")}
                value={field.value ?? ""}
                error={!!form.formState.errors.function}
                helperText={form.formState.errors.function?.message?.toString()}
                className={styles.formField}
              >
                {options}
              </SelectField>
            )}
            display={
              <Typography variant="subtitle1">
                {`${t("BLEBeacon.functionLabel")}: ${
                  currentDevice
                    ? t(`BLEBeacon.${currentDevice.function}`)
                    : t(`BLEBeacon.${DeviceFunction.InternalLocation}`)
                }`}
              </Typography>
            }
          />
          <ViewingModeController
            viewingMode={currentViewingMode}
            name="deviceProximityId"
            control={form.control}
            rules={registerOptions.deviceProximityId}
            render={({ field }) => (
              <TextField
                {...field}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        onClick={() => {
                          generateProximityId();
                          form.trigger("deviceProximityId");
                        }}
                        edge="end"
                      >
                        {!hasImportDate && <AutorenewRoundedIcon />}
                      </IconButton>
                    </InputAdornment>
                  ),
                  disabled: hasImportDate,
                }}
                label={t("BLEBeacon.proximityLabel")}
                value={field.value ?? ""}
                error={!!form.formState.errors.deviceProximityId}
                helperText={form.formState.errors.deviceProximityId?.message?.toString()}
                className={styles.formField}
              />
            )}
            display={
              <div className={styles.proximityId}>
                <Typography variant="subtitle1">
                  {`${t("BLEBeacon.proximityLabel")}: ${
                    currentDevice?.deviceProximityId
                  }`}
                </Typography>
                <CopyButton contentToCopy={currentDevice?.deviceProximityId} />
              </div>
            }
          />
        </div>
      </div>
    </>
  );
};

export default BLEBeaconSettings;
